


















import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
	components: {}
})
export default class ProgressCircular extends Vue {
	//circumference = ((2 * 22) / 7) * 23;

	@Prop({ default: false })
	small: boolean;

	@Prop({ default: 0 })
	value: number;

	@Prop({ default: '00:00' })
	time: string;

	@Prop({ default: 'gray' })
	strokeColor: string;

	@Prop({ default: '#efefef' })
	strokeBgColor: string;

	get circumference(): number {
		if (this.small) {
			let circumference = ((2 * 22) / 7) * 23;
			return circumference;
		} else {
			let circumference = ((2 * 22) / 7) * 120;
			return circumference;
		}
	}
}
